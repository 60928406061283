import splide, { customSplide } from "../utils/splide";

const banner = document.getElementById("banner");
if (banner) {
    const bannerSplide = customSplide(banner, {
        type: "fade",
        rewind: true,
        perPage: 1,
        // autoplay: true,
        fixedHeight: "444px",
        speed: 2000,
        lazyLoad: "sequential",
        pagination: false,
        arrows: true,
        breakpoints: {
            1200: {
                pagination: true,
                arrows: false,
                fixedHeight: "228px",
            },
            600: {
              fixedHeight: "unset",
              arrows: false,
              pagination: true,
            },
        },
    });
    bannerSplide.mount();
}

const course = document.getElementById("courses");
if (course) {
    const courseSplide = splide(course, {
        perPage: 4,
        arrows: true,
        gap: "24px",
        breakpoints: {
            1200: {
                perPage: 3,
                pagination: true,
                arrows: false,
            },
            600: {
                perPage: 1,
                pagination: true,
                arrows: false,
            },
        },
    });
    courseSplide.mount();
}

const structure = document.getElementById("structure");
if (structure) {
    const structureSplide = splide(structure, {
        perPage: 4,
        arrows: true,
        gap: "32px",
        breakpoints: {
            1200: {
                perPage: 2,
                pagination: true,
                arrows: false,
            },
            600: {
                perPage: 1,
                pagination: true,
                arrows: false,
            },
        },
    });
    structureSplide.mount();
}

const blog = document.getElementById("blog");
if (blog) {
    const blogSplide = splide(blog, {
        perPage: 3,

        breakpoints: {
            1200: {
                perPage: 3,
                pagination: true,
                arrows: false,
            },
            600: {
                perPage: 1,
                arrows: false,
                pagination: true,
            },
        },
    });
    blogSplide.mount();
}

const covenant = document.getElementById("covenants");
if (covenant) {
    const covenantSplide = splide(covenant, {
        perPage: 3,
        arrows: true,
        breakpoints: {
            1200: {
                perPage: 2,
                pagination: true,
                arrows: false,
            },
            600: {
                perPage: 1,
                pagination: true,
                arrows: false,
            },
        },
    });
    covenantSplide.mount();
}
